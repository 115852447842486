<script>
import ApiService from "@services/api.service";
import * as download from "downloadjs";

export default {
  page: {
    title: "Leads"
  },
  data() {
    return {
      table: {
        sortBy: "period",
        fields: [
          { key: "uploadDate", sortable: true },
          { key: "recordCount", class: "text-right" },
          { key: "actions", sortable: true, class: "text-right" }
        ],
        data: [],
        pageListOptions: [10, 20, 50, 100],
        paging: {
          pageNo: 1,
          pageSize: 10,
          recordCount: 1
        }
      },
      loader: {
        uploadButton: false,
        downloadButton: false
      },
      file: null,
      error: null,
      successCount: null
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data.length;
    }
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      this.changeLoader(true);
      ApiService.post("leads/batch", this.table.paging)
        .then(resp => {
          this.table.data = resp.records;
          this.table.paging.recordCount = resp.recordCount;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    uploadTemplate() {
      this.loader.uploadButton = true;
      this.resetData();
      let formData = new FormData();
      formData.append("file", this.file);
      ApiService.upload("leads/upload", formData)
        .then(resp => {
          this.showToast("success", "Upload success");
          this.loadTable();
          this.successCount = resp;
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.file = null;
          this.loader.uploadButton = false;
        });
    },
    downloadTemplate() {
      this.loader.downloadButton = true;
      ApiService.download("leads/template")
        .then(resp => {
          this.download(resp.data, "DropLeadsTemplate.xlsx");
        })
        .catch(err => this.showToast(err))
        .finally(() => {
          this.loader.downloadButton = false;
        });
    },
    resetData() {
      this.successCount = null;
      this.error = null;
    },
    cancelBatch(code) {
      this.showConfirm("Cancel will delete all leads on this batch.").then(resp => {
        if (resp) {
          this.changeLoader(true);
          ApiService.delete("Leads/cancel", { batchCode: code })
            .then(async () => {
              await this.loadTable();
              this.showToast("success", "Cancel Batch Success");
            })
            .catch(err => this.showToast(err))
            .finally(() => {
              this.changeLoader(false);
            });
        }
      });
    }
  }
};
</script>

<template>
  <div>
    <vue-element-loading
      :active="loader.uploadButton"
      is-full-screen
      text="Please wait.. Uploading Leads may take a while to finish. Please dont leave this page."
    />

    <b-card>
      <div class="d-flex mb-2">
        <b-form-file
          v-model="file"
          style="width: 300px"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Upload Leads Batch Template..."
          accept=".xlsx"
        />
        <b-button
          v-if="file"
          class="ml-2 d-flex align-items-center"
          variant="outline-info"
          :disabled="loader.uploadButton"
          @click.stop="uploadTemplate"
        >
          <b-spinner v-if="loader.uploadButton" variant="primary" label="Spinning" small class="mr-1" />
          <span>Upload</span>
        </b-button>
        <b-button class="ml-auto d-flex align-items-center" variant="outline-success" :disabled="loader.downloadButton" @click="downloadTemplate()">
          <b-spinner v-if="loader.downloadButton" variant="primary" label="Spinning" small class="mr-1" />
          Download Template
        </b-button>
        <v-select v-model="table.paging.pageSize" class="ml-2" placeholder="Record Per Page" :options="table.pageListOptions"></v-select>
      </div>

      <b-card v-if="successCount" title="You Have Uploaded Leads Successfully" border-variant="success" bg-variant="transparent">
        <hr />
        <h3>Record Count : {{ successCount | number }}</h3>
      </b-card>

      <b-card
        v-if="error"
        title="You have errors on your file."
        sub-title="Please check errors detail below."
        border-variant="danger"
        bg-variant="transparent"
      >
        <hr />
        <pre class="text-danger">{{ error }}</pre>
      </b-card>

      <b-table
        :items="table.data"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        :per-page="table.paging.pageSize"
        :current-page="table.paging.pageNo"
        :filter="table.filter"
        no-provider-filtering
        empty-text="No data to"
        striped
        outlined
        hover
        show-empty
      >
        <template #cell(uploadDate)="data">
          <div>
            {{ data.item.uploadDate | dateTime }}
          </div>
        </template>
        <template #cell(recordCount)="data">
          <div>
            {{ data.item.recordCount | number }}
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <b-btn class="ml-auto" variant="outline-danger" @click="cancelBatch(data.item.code)"> Cancel Batch </b-btn>
          </div>
        </template>
      </b-table>

      <div class="d-flex mt-2">
        <span class="mr-auto"
          >Showing of <span class="font-weight-bold">{{ rowCount }}</span> of
          <span class="font-weight-bold">{{ table.paging.recordCount }}</span></span
        >
        <b-pagination
          v-model="table.paging.pageNo"
          class="ml-auto"
          :total-rows="table.paging.recordCount"
          :per-page="table.paging.pageSize"
          align="center"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>
